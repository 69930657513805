import React from "react";
import { styled } from "linaria/react";
import { theme } from "../Theme/Theme";
import { LogoLink } from "../LogoLink/LogoLink";
import { Container, Grid } from "@material-ui/core";
import { FooterContactItems } from "./components/FooterContactItems/FooterContactItems";
import { FooterNavItems } from "./components/FooterNavItems/FooterNavItems";
import { FooterSocialLinks } from "./components/FooterSocialLinks/FooterSocialLinks";

export interface FooterProps {
  className?: string;
}

const FooterInner = (props: FooterProps) => {
  return (
    <footer className={props.className}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3} container alignItems="center">
            <LogoLink light />
          </Grid>
          <Grid item xs={6} md={3}>
            <FooterNavItems />
          </Grid>
          <Grid item xs={6} md={3}>
            <FooterSocialLinks />
          </Grid>
          <Grid item xs={12} md={3}>
            <FooterContactItems />
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export const Footer = styled(FooterInner)`
  background: ${theme.color.primaryDark};
  padding: 6rem 0;

  color: ${theme.color.background};
  /* margin-top: 16rem; */
`;
