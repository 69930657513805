import { styled } from "linaria/react";
import { rgba } from "polished";
import React, { ReactNode } from "react";
import { theme } from "../../../../Theme/Theme";

export interface FooterContactItemProps {
  className?: string;
  label: string;
  href: string;
  icon?: ReactNode;
}

const FooterContactItemInner = (props: FooterContactItemProps) => {
  const { label, href, icon } = props;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={props.className}>
      <div className="icon">{icon}</div>
      <div className="text">{label}</div>
    </a>
  );
};

export const FooterContactItem = styled(FooterContactItemInner)`
  display: flex;
  color: ${theme.color.light};
  text-decoration: none;
  transition: all 230ms ease-in-out;
    filter: opacity(0.6);

  &:hover {
    filter: opacity(1);
  }

  .icon {
    margin-right: 15px;
    font-size: 23px;
    color: ${theme.color.primary};
    display: flex;
    align-items: center;
  }

  .text {
    display: flex;
    align-items: center;
  }
`;
