import { Container } from "@material-ui/core";
import { Link } from "gatsby";
import { css } from "linaria/lib";
import { styled } from "linaria/react";
import React from "react";
import { useRoutes } from "../../../lib/routes";
import { LogoLink } from "../LogoLink/LogoLink";
import { muiTheme, theme } from "../Theme/Theme";
import { MobileMenu } from "./components/MobileMenu";

const NavLink = css`
  font-size: 1.8rem;
  font-weight: 800;
  color: inherit;
  margin: 0 20px;
  height: 4rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    transition: all 320ms ease-in-out;
    height: 4px;
    background: ${theme.color.primary};
    width: 80%;
    display: flex;
    position: absolute;
    bottom: 0px;
    left: -80%;
  }

  &:hover {
    &::after {
      left: 0px;
    }
  }
  &.active {
    &::after {
      left: 0px;
    }
  }
`;

export interface HeaderProps {
	light?: boolean;
}

const HeaderStyled = styled.header<HeaderProps>`
  display: flex;
  height: ${theme.sizing.headerHeight};
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  color: ${props => props.light ? theme.color.light : theme.color.primaryDark};

  nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .nav-items {
    display: flex;
    align-items: center;
  }

  ${muiTheme.breakpoints.down("sm")} {
    .nav-items {
      display: none;
    }

  }
`;

const HeaderInner = (props: HeaderProps) => {
	const { light } = props;
	const routes = useRoutes();

	return (
		<HeaderStyled light={light}>
			<Container maxWidth={'lg'}>
				<nav>
					<LogoLink light={light} />
					<div className="nav-items">
						{Object.values(routes).map((route) => (
							<Link
								className={NavLink}
								key={route.to}
								activeClassName="active"
								to={route.to}
							>
								{route.label}
							</Link>
						))}
					</div>
					<MobileMenu />
				</nav>
			</Container>
		</HeaderStyled>
	);
};

export const Header = HeaderInner;
