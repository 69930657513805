import { styled } from "linaria/react";
import React from "react";
import { FooterContactItem } from "./components/FooterContactItem";
import { FiMail, FiPhone } from "react-icons/fi";

export interface FooterContactItemsProps {
  className?: string;
}

const FooterContactItemsInner = (props: FooterContactItemsProps) => {
  return (
    <article className={props.className}>
      <FooterContactItem icon={<FiPhone />} href="tel:+421948523997" label="+421 948 523 997" />
      <FooterContactItem icon={<FiMail />} href="mailto:info@colderia.sk" label="info@colderia.sk" />
    </article>
  );
};

export const FooterContactItems = styled(FooterContactItemsInner)`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  height: 100%;
  
  & > * {
    &:not(:last-child) {
    margin-bottom: 10px 
    }
  }
`;
