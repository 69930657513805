import React from "react";
import { ReactNode } from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

export interface SocialLink {
  icon: ReactNode;
  label: string;
  url: string;
}

export const useSocialLinks = (): SocialLink[] => {
  return [
    {
      label: "Facebook",
      icon: <FaFacebookF />,
      url: 'https://www.facebook.com/pages/category/Product-Service/Colderia-Slovakia-sro-106381284564501/'
    },
    {
      label: "Instagram",
      icon: <FaInstagram />,
      url: 'https://www.instagram.com/colderia_slovakia/'
    },
    {
      label: "Youtube",
      icon: <FaYoutube />,
      url: 'https://www.youtube.com/channel/UCVJZihKIPEpH5yPyOxFadCw'
    },
  ];
};
