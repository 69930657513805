import { createMuiTheme } from "@material-ui/core";

const primaryColor = "#C79B5C";
const textColor = "#585858";

export const theme = {
  name: "Default",
  color: {
    background: "#ffffff",
    primary: primaryColor,
    primaryDark: "#050505",
    light: "#F5F5F5",
    gray: textColor,
    text: textColor,
  },
  borderRadius: "16px",
  spacing: {
    sectionMarginTop: "10rem",
  },
  sizing: {
    headerHeight: "160px",
  },
};

export const muiTheme = createMuiTheme();
