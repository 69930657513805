export interface Route {
  label: string;
  to: string;
  hideInMenu?: boolean;
}

export const routes: Record<string, Route> = {
  home: {
    label: "Domov",
    to: "/",
  },
  showroom: {
    label: 'Ponuka',
    to: '/ponuka/'
  },
  contact: {
    label: 'Kontakt',
    to: '/kontakt/'
  }
};


export const generateCarRoute = (carSlug: string) => `/ponuka/${carSlug}/`
export const generateQuestionRoute = (questionSlug: string) => `/otazky-a-odpovede/${questionSlug}/`


export const useRoutes = () => {
  return routes;
}