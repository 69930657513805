import { Link } from "gatsby";
import { styled } from "linaria/react";
import React from "react";
import { routes } from "../../../lib/routes";
import SvgLogo from "./assets/Logo";
import SvgLogoWhite from "./assets/LogoWhite";

export interface LogoLinkProps extends React.HTMLAttributes<HTMLElement> {
  light?: boolean;
}

const LogoLinkInner: React.FC<LogoLinkProps> = (props: LogoLinkProps) => {
  const { light } = props;
  return (
    <Link to={routes.home.to} className={props.className}>
      {light ? <SvgLogoWhite /> : <SvgLogo />}
    </Link>
  );
};

export const LogoLink = styled(LogoLinkInner)``;
