import { Link } from "gatsby";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { useRoutes } from "../../../../../lib/routes";
import { theme } from "../../../Theme/Theme";

export interface FooterNavItemsProps {
  className?: string;
}

const navItemStyles = css`
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  color: ${theme.color.light};
  filter: opacity(0.4);
  transition: all 200ms ease-in-out;


  &:hover {
    filter: opacity(1);
  }
`;

const FooterNavItemsInner = (props: FooterNavItemsProps) => {
  const routes = useRoutes();
  return (
    <article className={props.className}>
      {Object.values(routes)
        .filter((route) => !route.hideInMenu)
        .map((route) => (
          <Link className={navItemStyles} to={route.to}>
            {route.label}
          </Link>
        ))}
    </article>
  );
};

export const FooterNavItems = styled(FooterNavItemsInner)`
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  height: 130px
`;
