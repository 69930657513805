import { styled } from "linaria/react";
import React from "react";
import { theme } from "../../../../Theme/Theme";
import { SocialLink } from "../hooks/useSocialLinks";

export interface FooterSocialLinkProps extends SocialLink {
  className?: string;
}

const FooterSocialLinkInner = (props: FooterSocialLinkProps) => {
  const { label, url, icon } = props;
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={props.className}
    >
      <div className="icon">{icon}</div>
      <div className="label">{label}</div>
    </a>
  );
};

export const FooterSocialLink = styled(FooterSocialLinkInner)`
  display: flex;
  color: ${theme.color.light};
  text-decoration: none;
  transition: all 230ms ease-in-out;
  filter: opacity(0.6);

  &:hover {
    filter: opacity(1);
  }

  .icon {
    margin-right: 15px;
    font-size: 23px;
    color: ${theme.color.primary};
    display: flex;
    align-items: center;
  }

  .text {
    display: flex;
    align-items: center;
  }
`;
