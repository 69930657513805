import { styled } from "linaria/react";
import React from "react";
import { ReactNode } from "react";
import { FooterSocialLink } from "./components/FooterSocialLink";
import { useSocialLinks } from "./hooks/useSocialLinks";

export interface FooterSocialLinksProps {
  className?: string;
}

const FooterSocialLinksInner = (props: FooterSocialLinksProps) => {
  const socialLinks = useSocialLinks();
  return (
    <article className={props.className}>
      {socialLinks.map((link) => (
        <FooterSocialLink key={link.label} {...link} />
      ))}
    </article>
  );
};

export const FooterSocialLinks = styled(FooterSocialLinksInner)`
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 130px;
  justify-content: space-evenly;
`;
